export  enum politics_status {
  '群众'=1,
  '党员',
  '其他'
}

// 入学前学历,1初中、2高中、3大专、4本科、5其他		
export  enum second_degree {
  '初中'=1,
  '高中',
  '大专',
  '本科',
  '其他',
}
export default [
  // {
  //   label: "电子邮箱",
  //   type: "text",
  // },
  {
    label: "学号",
    id: "student_number",
    disabled:true,
    type: "text", value: ''
  },
  {
    label: "姓名",
    disabled:true,
    id: "real_name",
    type: "text", value: ''
  },
  {
    label: "所在省市区",
    id: "area",
    type: "area", value: ''
  },
  {
    label: "民族",
    id: "nationality",
    type: "text", value: ''
  },
  {
    label: "政治面貌",
    id: "politics_status",
    type: "select", value: '',
    options:['群众',
    '党员',
    '其他'],
    enumData:politics_status
  },
  
  {
    label: "职业状况",
    id: "employment_status",
    type: "text", value: ''
  },
  {
    label: "联系地址",
    id: "address",
    type: "text", value: ''
  },
  {
    label: "入学前学历",
    id: "second_degree",
    type: "select", value: '',
    options:['初中',
    '高中',
    '大专',
    '本科',
    '其他',],
    enumData:second_degree
  },
  {
    label: "职业",
    id: "employment",
    type: "text", value: ''
  },
  {
    label: "工作单位",
    id: "company",
    type: "text", value: ''
  },
  
  // {
  //   label: "手机号",
  //   id: "phone",
  //   type: "text", value: ''
  // },
];
