import { useSnackbar } from 'notistack';
import axios from 'axios'
import { serviceOptions } from './config'
import { useNavigate } from 'react-router-dom';
var apiAddress = require('../../package.json').apiAddress

export function SettingApiConfig(){
  const { enqueueSnackbar } = useSnackbar();
  // let navigate = useNavigate();
  let address = ''
if(process.env.NODE_ENV === 'production'){
    address = apiAddress.production
}else{
    address = apiAddress.development
}
const instance = axios.create({
  baseURL: address,
  timeout: 100000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log('response 200',response)
  if(response.data.code!=0){
    
    enqueueSnackbar(''+response?.data?.error +" ",{variant:'error',anchorOrigin:{vertical:'top',horizontal:'right'},autoHideDuration:2000});
    if(response.data.code == -1){
      // navigate('/login')
      location.href='/login'
    }
  }
  return response;
}, function (error) {
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log('err',error)
  // if(error.response.status == 401){
  //   localStorage.setItem('beforePath',window.location.href)
  //   // window.location.href='/sign-in'
    
  // }
  enqueueSnackbar('HTTP ERROR',{variant:'error',anchorOrigin:{vertical:'top',horizontal:'right'},autoHideDuration:2000});
  return Promise.reject(error);
});
// let token = localStorage.getItem('access_token');
// if(token){

//   instance.defaults.headers.common['Authorization'] = token
// }




serviceOptions.axios = instance
}

