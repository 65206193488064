// import { useSnackbar } from "notistack";
// const { enqueueSnackbar } = useSnackbar();
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
export function errorMessage(msg: string, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey) {
    enqueueSnackbar(msg, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 1000 });
}
export function warningMessage(msg: string, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey) {
    enqueueSnackbar(msg, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 1000 });
}
export function successMessage(msg: string, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,duration=1000) {
    enqueueSnackbar(msg, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: duration });
}