import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


// const {
//     pwdSmsCode,
//     pwdSmsPhone
//   } = useAppSelector((state) => {
//     return state.userInfo;
//   });

// let navigate = useNavigate();

//     const dispatch = useAppDispatch();