import { Box, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {  setPwdSmsCode } from "../../app/userInfoSlice";
import { warningMessage, errorMessage, successMessage } from "../../utils/Message";
import { SendVerifyCode } from "./SendVerifyCode";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoginService } from "../../api/services/LoginService";
export function ResetPwd() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])

    let [password, setPassword] = useState("")
    let [confirmPassword, setConfirmPassword] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        pwdSmsCode,
        pwdSmsPhone
      } = useAppSelector((state) => {
        return state.userInfo;
      });
    
      useEffect(()=>{
        if(pwdSmsPhone=='' || pwdSmsCode==''){
            // warningMessage('请先输入手机号码和验证码', enqueueSnackbar)
            // return
            navigate('/forgetPwd')
        }
      },[])

    return <Box className="fixedContainer">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: "#5b5b5b", boxShadow: 'inherit' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                        navigate('/forgetPwd')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    重置密码
                </Typography>
            </Toolbar>
        </AppBar>
        <Box sx={{ margin: '0 50px', marginTop: '200px', }}>
            <TextField fullWidth size="small" label="请输入密码" variant="outlined" type="password" value={password} onChange={(event) => {

                setPassword(event.target.value)
            }} />
            <TextField type="password" sx={{ marginTop: '20px' }} fullWidth value={confirmPassword} size="small"
                label="请再次输入密码" variant="outlined" onChange={(event) => {

                    setConfirmPassword(event.target.value)
                }} />
            <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                onClick={() => {
                    // if (phone == "" || phone.length != 11) {
                    //     warningMessage('请输入正确手机号码', enqueueSnackbar)

                    //     // alert('请输入正确手机号码')
                    //     return
                    // }
                    // if (verifyCode == "") {
                    //     warningMessage('请输入验证码', enqueueSnackbar)
                    //     return
                    // }
                    // dispatch(smsLogin({sms_code:verifyCode,phone:phone})).then(res=>{
                    //     if(res){
                    //         navigate('/')
                    //     }
                    // })
                    // dispatch(setPwdSmsCode(verifyCode))

                    if(pwdSmsPhone=='' || pwdSmsCode==''){
                        warningMessage('请先输入手机号码和验证码', enqueueSnackbar)
                        return
                    }

                    if(password=='' ||confirmPassword==''){

                        warningMessage('请输入密码', enqueueSnackbar)
                        return
                    }

                    if(password!=confirmPassword){
                        warningMessage('密码不一致', enqueueSnackbar)
                        return
                    }
                    // dispatch(setPwd())
                    // navigate('/login')
                    LoginService.setPwd({ body: { password:password,phone:pwdSmsPhone,sms_code:pwdSmsCode} }).then(res=>{
                        console.log('resetpwd',res)
                        successMessage('密码已重置', enqueueSnackbar,3000)
                        navigate('/login')
                    })
                }}
            >完成</Button>
        </Box>
    </Box>
}