export  enum sexEnum {
  '男'=1,
  '女',
  '未知'
}

export default [
    // {
    //   label: "电子邮箱",
    //   type: "text",
    // },
    {
      label: "学号",
      id: "student_number",
      type: "text", value: ''
    },
    {
      label: "姓名",
      id: "real_name",
      type: "text", value: ''
    },
    
    // {
    //   label: "手机号",
    //   id: "phone",
    //   type: "text", value: ''
    // },
    
    {
      label: "性别",
      id: "sex",
      type: "text", value: ''
    },
    {
      label: "证件号码",
      id: "id_card",
      type: "text", value: ''
    },
    {
      label: "出生日期",
      id: "birthday_str",
      type: "text", 
      value: ''
    },
    {
      label: "教学点",
      id: "teaching_point_name",
      type: "text", value: ''
    },
    {
      label: "就读班级",
      id: "class_name",
      type: "text", value: ''
    },
    {
      label: "入学批次",
      id: "admission_date_name",
      type: "text", value: ''
    },
    {
      label: "就读专业",
      id: "major_name",
      type: "text", value: ''
    },
    {
      label: "学制",
      id: "schooling_period_name",
      type: "text", value: ''
    },
  ];
  