import { axios, basePath, getConfigs, IRequestConfig, IRequestOptions } from "../config";

import { SuccessCommom } from "../CommonInterface";



export interface User {
    avatar: string
    avatar_body: string
    avatar_pre: string
    token: string
    user_id: string
    uuid: string
    created_at: string
    updated_at: string
    deleted_at: string
    password: string
    salt: string
    boss_user_id: string
    email: string
    phone: string
    nickname: string
    username: string
    status: string
    sex: string
    reg_from: string
    card_type: string
    id_card: string
    from_city_id: string
    from_item_category_id: string
    birthday_str: string
    birthday: string
    sign_remark: string
    edges: string
}
export interface pwdLoginParams {

    phone: string
    password: string

}
// export interface pwdLoginRes extends SuccessCommom {
//     // data: {
//     //     token: string
//     //     user_id: string
//     //     User: User
//     // }
//     data:User
// }
export interface sendSmsRes extends SuccessCommom {
    data: {
        sms_code: string
        phone: string
    }
}
export interface smsLoginParams {

    phone: string
    sms_code: string
}
export interface setPwdParams {

    phone: string
    sms_code: string
    password: string
}
export interface updatePhoneParams {
    sms_code: string
    new_phone: string
    phone: string
    password?: string
}
// export interface smsLoginRes extends SuccessCommom {
//     data: {
//         sms_code:string
//         phone:string
//     }
// }

export interface OssAuthObj {
    accessid: string
    host: string
    policy: string
    signature: string
    expire: string
    callback: string
    key: string
    callback_info: {
        callback_url: string
        callback_body: string
        callback_body_type: string
    }
    token: {
        access_key_secret: string
        access_key_id: string
        expiration: string
        security_token: string
    }
}

export interface uploadFileDto {
    action: string
    admin_id: number
    created_at: string
    file_size: string
    filename: string
    id: number
    mime_type: string
    updated_at: string
    user_id: number
}

export interface setBasicInfoParams {

    id: string
    phone: string
    student_number: string
    real_name: string
    id_card: string
    card_type: string
    sex: string
    birthday: string
    area: string
    nationality: string
    employment_status: string
    employment: string
    company: string
    address: string
    politics_status: string
    second_degree: string
    teaching_point_id: string
    city_id: string
    cate_id: string
}
//   export interface getOssAuthDto extends SuccessCommom {
//     data: OssAuthObj
//   }
export class LoginService {
    static get_ck_user_info(
        params: {
            /** requestBody */
            body?: {user_id:string};
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<User> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/auth/get_ck_user_info';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    static user_set(
        params: {
            /** requestBody */
            body?: setBasicInfoParams;
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<null> {
        return new Promise((resolve, reject) => {
            let url = '/admin/manager/user_set';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    static getOssAuth(
        params: {
            /** requestBody */
            body?: { action: string, media?: string };
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<OssAuthObj> {
        return new Promise((resolve, reject) => {
            let url = '/admin/basic_config/oss/auth';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    static uploadFile(
        host: string,
        params: {
            /** requestBody */
            body?: { formData: FormData, };
        } = {} as any,

        options: IRequestOptions = {}
    ): Promise<uploadFileDto> {
        return new Promise((resolve, reject) => {
            let data = params.body;
            if (host && data?.formData) {

                const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', host, options);
                configs.data = data?.formData;
                axios(configs, resolve, reject);
            }
        });
    }
    /**
     * 修改密码
     */
    static user_update_pwd(
        params: {
            /** requestBody */
            body?: { password: string, new_password: string, phone: string }
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<null> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/auth/user_update_pwd';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * 验证码检验：修改手机号
     */
    static update_phone(
        params: {
            /** requestBody */
            body?: updatePhoneParams
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<null> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/auth/update_phone';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * 验证码检验：修改手机号
     */
    static user_check_code(
        params: {
            /** requestBody */
            body?: { phone: string, sms_code: string }
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<null> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/user_check_code';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * 找回密码
     */
    static setPwd(
        params: {
            /** requestBody */
            body?: setPwdParams
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<null> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/set_pwd';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }


    /**
     * 密码登录
     */
    static pwdLogin(
        params: {
            /** requestBody */
            body?: pwdLoginParams;
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<User> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pwd_login';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }

    /**
     * 获取验证码
     */
    static sendSms(
        params: {
            /** requestBody */
            body?: { phone: string };
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<sendSmsRes> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/send_sms';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }

    /**
     * 短信登录
     */
    static smsLogin(
        params: {
            /** requestBody */
            body?: smsLoginParams
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<User> {
        return new Promise((resolve, reject) => {
            let url = basePath + '/sms_login';

            const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

            let data = params.body;

            configs.data = data;
            axios(configs, resolve, reject);
        });
    }

}