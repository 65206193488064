import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
// import {
//   LoginDto,
//   AuthService,
//   serviceOptions,
//   ResponseUserInfoDto,
// } from "../api/swaggerApi/index";

import { AxiosError } from 'axios'
import { ValidationErrors } from "../api/CommonInterface";
import { serviceOptions } from "../api/config";
import { courseDetailParams, courseDetailRes, courseList, LearnService, userCoureListRes } from "../api/services/LearnService";
import { LoginService, smsLoginParams, pwdLoginParams, User, setPwdParams } from "../api/services/LoginService";
// import { hiddenPhone } from "../utils/hiddenPhone";


type userInfoItem = {
  id: string,
  name: string,
}




export const get_user_course_list = createAsyncThunk<
  userCoureListRes,
  { user_id: string },
  // { id: string } & Partial<LoginDto>,
  {
    rejectValue: ValidationErrors
  }
>('LearnSlice/get_user_course_list', async (params, { rejectWithValue }) => {
  try {
    let result = await LearnService.get_user_course_list({ body: { user_id: params.user_id } })

    return result


  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})
export const get_ck_course_details = createAsyncThunk<
  courseDetailRes,
  courseDetailParams,
  // { id: string } & Partial<LoginDto>,
  {
    rejectValue: ValidationErrors
  }
>('LearnSlice/get_ck_course_details', async (params, { rejectWithValue }) => {
  try {
    let result = await LearnService.get_ck_course_details({ body: { user_id: params.user_id, course_id: params.course_id } })

    return result


  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})
let initialState = {
  courseInfo: <userCoureListRes | null>null,
  courseDetail: <courseDetailRes | null>null,

  videoBreadcrumbs: '',


  
  
  course_id:'',
  chapter_id:'',
  section_id:'',
  section_name:'',
}
export const LearnSlice = createSlice({
  name: "LearnSlice",
  initialState: initialState,
  reducers: {
    ResetLearnSlice(state) {
      // ✅ CORRECT: returns a new value to replace the old one
      return initialState
    },
    setVideoBreadcrumbs(state, action: PayloadAction<{ name: string, course_id: string,chapter_id:string,section_id:string,section_name:string }>) {
      state.videoBreadcrumbs = action.payload.name
      


      state.course_id = state.courseDetail?.course_id!

      state.chapter_id = action.payload.chapter_id
      state.section_id = action.payload.section_id
      state.section_name = action.payload.section_name

    },
    //   setUserInfo(state,action: PayloadAction<User>){
    //     state.token = action.payload.token
    //     state.userinfo = action.payload

    //   },

  },
  extraReducers: (builder) => {
    builder.addCase(get_ck_course_details.pending, (state, { payload }) => {
      // state.smsLoginLoading = true
      // state.token = payload.data.token
      // state.userinfo = payload.data.User
    })
    builder.addCase(get_ck_course_details.fulfilled, (state, { payload }) => {
      // state.token = payload.token
      // state.userinfo = payload
      // state.smsLoginLoading = false
      state.courseDetail = payload
    })
    builder.addCase(get_ck_course_details.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        // state.error = action.payload.errorMessage
      } else {
        // state.error = action.error.message!
      }
    })
    builder.addCase(get_user_course_list.pending, (state, { payload }) => {
      // state.smsLoginLoading = true
      // state.token = payload.data.token
      // state.userinfo = payload.data.User
    })
    builder.addCase(get_user_course_list.fulfilled, (state, { payload }) => {
      // state.token = payload.token
      // state.userinfo = payload
      // state.smsLoginLoading = false
      state.courseInfo = payload
    })
    builder.addCase(get_user_course_list.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        // state.error = action.payload.errorMessage
      } else {
        // state.error = action.error.message!
      }
    })
  },
});

export const {
  ResetLearnSlice,
  setVideoBreadcrumbs
  // loginOut,
  // setUserInfo,
  // setPwdSmsCode
} = LearnSlice.actions;

export default LearnSlice.reducer;
