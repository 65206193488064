import * as React from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import provinces from './provinces.json'
import { useEffect, useState } from 'react';

interface item {
  code: string
  value: string
  label: string
}
interface itemSub {
  code: string
  value: string
  label: string
  children:item[]
}
interface childTypeSub {
  code: string
  value: string
  label: string
  children: childTypeSub[]
}
interface Props{
  onChange:Function
}
export default function AreaPick(props:Props) {
  let {onChange} = props
  let [level1, setLevel1] = useState('')
  let [level2, setLevel2] = useState('')
  let [level3, setLevel3] = useState('')

  let [level1Arr, setLevel1Arr] = useState()
  let [level2Arr, setLevel2Arr] = useState<itemSub[]|childTypeSub[]>()
  let [level3Arr, setLevel3Arr] = useState<item[]>()

  useEffect(()=>{
    onChange(level1,level2,level3)
  },[level1,level2,level3])

  // function getCity() {
  //   let item = provinces.find(v => {
  //     return v.label == level1
  //   })
  //   if(item){

  //     return item?.children
  //   }
  //   return []
  // }
  // function getArea() {
  //   let citys = getCity()
  //   if (citys) {

  //     // if(citys.childten){

  //     // }
  //     // @ts-ignore
  //     let area: childTypeSub|undefined = citys.find(v => {

  //       return v.label == level2
  //     })

  //     if (area && area.children) {
  //       return area.children
  //     }


  //     //   if(item){

  //     //     return item.children
  //     //   }
  //   }
  // }


  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };


  return (
    <Box >
      {/* <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={level1}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box> */}
      <Box sx={{ margin: '10px' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label1">省份</InputLabel>

          <Select

            fullWidth
            labelId="demo-simple-select-label1"
            // id="demo-simple-select"
            value={level1}
            label="省份"
            onChange={(event: SelectChangeEvent) => {
              let pick = event.target.value as string
              setLevel1(pick);
              setLevel2("")
              setLevel3("")
              let item = provinces.find(v => {
                return v.label == pick
              })
              if(item){
                console.log('设置省份',item)
                setLevel2Arr(JSON.parse(JSON.stringify(item.children)))
              }
              setLevel3Arr([])
              // if (item) {

              //   return item?.children
              // }
              // return []
            }}
          >
            {
              provinces.map(v => {
                return <MenuItem value={v.value}>{v.value}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ margin: '10px' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label2">市</InputLabel>

          <Select

            fullWidth
            labelId="demo-simple-select-label2"
            // id="demo-simple-select"
            value={level2}
            label="市"
            onChange={(event: SelectChangeEvent) => {
              let city = event.target.value as string
              setLevel2(city);
              setLevel3("")
              if(level2Arr){
                let res = level2Arr.find(v=>{
                  return v.label == city
                }) as childTypeSub|itemSub|undefined

                if(res){
                  if('children' in res){
                    // return res.children
                    setLevel3Arr(res.children)
                  }
                }
              }
              
            }}
          >
            {
              // @ts-ignore
              level2Arr && level2Arr.map(v => {
                return <MenuItem value={v.value}>{v.value}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ margin: '10px' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label3">区</InputLabel>

          <Select

            fullWidth
            labelId="demo-simple-select-label3"
            // id="demo-simple-select"
            value={level3}
            label="区"
            onChange={(event: SelectChangeEvent) => {
              setLevel3(event.target.value as string);
            }}
          >
            {
              level3Arr && level3Arr.map(v => {
                return <MenuItem value={v.value}>{v.value}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}