import React, { Fragment } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

import RequireAuth from './app/requireAuth';
// import { Message } from './pages/Message';

// import { VerifyPage } from './pages/verifyPage';
import { Login } from './pages/Login/login';
import { Home } from './pages/Home';
import { SettingApiConfig } from './api';
import { ForgetPwd } from './pages/Login/forgetPwd';
import { ResetPwd } from './pages/Login/resetPwd';
import { AlterPhone } from './pages/My/AlterPhone';
import { AlterPhoneNewPhone } from './pages/My/AlterPhoneNewPhone';
import { AlterPwd } from './pages/My/AlterPwd';
import { BasicInfo } from './pages/My/BasicInfo';
import { StudentInfo } from './pages/My/StudentInfo';
function App() {
  SettingApiConfig()

  return (
    <div className="App">
      <Fragment>
        <Routes>

          {/* <Route path="/message" element={<RequireAuth><Message /></RequireAuth>} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgetpwd" element={<ForgetPwd />} />
          <Route path="/resetPwd" element={<ResetPwd />} />
          <Route path="/alterphone" element={<RequireAuth><AlterPhone /></RequireAuth>} />
          <Route path="/alterphone-newphone" element={<RequireAuth><AlterPhoneNewPhone /></RequireAuth>} />
          <Route path="/alterpwd" element={<RequireAuth><AlterPwd /></RequireAuth>} />
          <Route path="/basicInfo" element={<RequireAuth><BasicInfo /></RequireAuth>} />
          <Route path="/studentInfo" element={<RequireAuth><StudentInfo /></RequireAuth>} />
          <Route path="/*" element={<RequireAuth><Home /></RequireAuth>} />

        </Routes>
      </Fragment>

    </div>
  );
}

export default App;
