import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack";
import { SetStateAction, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../api/services/LoginService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { successMessage, warningMessage } from "../../utils/Message";
import { SendVerifyCode } from "../Login/SendVerifyCode";


// import logo from '../../static/images/logo-new@2x.png'
// import { warningMessage, successMessage } from "../../utils/Message";
// import { SendVerifyCode } from "./SendVerifyCode";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { userInfo } from "os";


export function AlterPhoneNewPhone() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])
    let [tabsValue, setTabsValue] = useState(0)
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")
    let [phone, setPhone] = useState("")
    let [verifyCode, setVerifyCode] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });



    return <Box className="fixedContainer">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: "#5b5b5b", boxShadow: 'inherit' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                        navigate('/alterphone')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    绑定新手机
                </Typography>
            </Toolbar>
        </AppBar>
        {userinfo?<Box sx={{ margin: '20px',marginTop:'50px' }}>
            <TextField fullWidth size="small" label="请输入新手机号" variant="outlined" value={phone} onChange={(event) => {

                setPhone(event.target.value)
            }} />
            <Box className="flex justify-between items-center" sx={{ marginTop: '20px', }}>
                <TextField value={verifyCode} size="small"
                    label="请输入验证码" variant="outlined" onChange={(event) => {

                        setVerifyCode(event.target.value)
                    }} />

                <SendVerifyCode phone={phone} onSendSms={() => {


                }} />
            </Box>
            <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                onClick={() => {
                    LoginService.update_phone({body:{
                        sms_code:verifyCode,
                        phone:userinfo.phone,
                        new_phone:phone
                    }}).then(res=>{
                        successMessage("修改成功，请重新登录",enqueueSnackbar)
                        navigate("/login")
                    })
                }}
            >绑定</Button>
        </Box>:null}
        
    </Box>

} 