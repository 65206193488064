import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { LoginService } from "../../api/services/LoginService";
import { warningMessage, successMessage } from "../../utils/Message";

let initTime = 60
let intervalId: number | NodeJS.Timeout | null = null

interface Props {
    onSendSms:Function,
    phone:string
}
export function SendVerifyCode(props:Props) {
    const { enqueueSnackbar } = useSnackbar();
    let {onSendSms,phone} = props
    const timeRef: any = useRef()
    const startCountRef: any = useRef()

    timeRef.current = () => {
        countDown(count + 1);
    }
    startCountRef.current = () => {
        intervalId = setInterval(() => {
            timeRef.current()

        }, 1000)
    }


    const [verifyCode, setVerifyCode] = useState('')
    const [count, setCount] = useState(initTime)
    const [showResend, setShowResend] = useState(false)
    function countDown(count: number) {
        // alert(1)
        let newTime = count - 1
        if (newTime <= 1) {
            setShowResend(false)
            if (typeof intervalId == 'number') {
                clearInterval(intervalId)

            }
            setCount(initTime)
            return false
        }
        // console.log(newTime)
        setCount(v => {
            return v - 1;
        })
    }
    useEffect(() => {
        // startCountRef.current()
        return () => {
            if (typeof intervalId == 'number') {
                clearInterval(intervalId)

            }
        }
    }, [])
    return <Box className="bothCenter " sx={{width:"150px",height:'40px'}}>
        {showResend ? <Typography variant="caption" component="span" >
            <Typography variant="caption" component="span" sx={{ color: 'warning.main' }}>
                {count}
            </Typography> 秒
        </Typography> : <Button  size="large" variant="text" onClick={() => {
            if(phone=="" || phone.length!=11){
                warningMessage('请输入正确手机号码',enqueueSnackbar)
            }else{
                
                LoginService.sendSms({body:{phone:phone}}).then(res=>{
                    if(res){
                        
                        setShowResend(true)
                        startCountRef.current()
                        onSendSms()
                        successMessage('验证码已发送',enqueueSnackbar)
                    }
                })
            }
        }}>获取验证码</Button>}
        
    </Box>
}