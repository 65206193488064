import { Box, Button, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from 'react-router-dom';
// import { useAppDispatch } from "../app/hooks";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// import { UserService } from "../api/UserService";
// import { UserService } from './api/UserService';
// 
import tempImage from '../../static/images/noImg.png'
import { cateSmallDetailRes, LearnService } from "../../api/services/LearnService";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';

let videoTime = 0
export function LearnVideo() {

    let { id } = useParams();

    let [message, setMessage] = useState('验证中...')
    let [showPlayBtn, setShowPlayBtn] = useState(true)
    // let [videoTime, setViewTime] = useState("")
    let [isPlayed, setIsPlayed] = useState(false)
    let [token, setToken] = useState('')
    let [videoId, setVideoId] = useState('')
    // let [videoObj,setVideoObj] = useState<cateSmallDetailRes>()

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });
    const {
        courseDetail,
        videoBreadcrumbs,
        course_id,
        
        chapter_id,
        section_id,
        section_name
    } = useAppSelector((state) => {
        return state.LearnSlice;
    });

    useEffect(() => {
        if(!courseDetail){
            navigate('/learn')
        }
        if (userinfo && id) {
            // dispatch(get_ck_course_details({ user_id: userinfo?.user_id, course_id: Number(id) }))
            LearnService.get_cate_small_detail({body:{small_id:Number(id)}}).then(res=>{
                // console.log('视频',res)
                setToken(res.token)
                setVideoId(res.video_id)
                // setVideoObj(res)
                let videoObj:cateSmallDetailRes = res
                // if(token){


                    // @ts-ignore
                    window.player = new bjcPlayer('#mainContainer', {
                        token: res.token,
                        definition: 'low', // high 高清 low 低清
                        privateDomainPrefix: '', // 专属域名
                        playbackRates: [],
                        onended: function () {
                            // console.log('onended');
                            // setRecord()
                            LearnService.add_ck_video_record({body:{
                                video_id:videoObj?.video_id,
                                is_complete:"1",
                                small_id:Number(id!),
                                video_time:videoTime,
                                course_id:videoObj?.course_id,
                                chapter_id:videoObj?.chapter_id,
                                name:videoObj?.small_name,
                                section_id:videoObj?.section_id}})
                        },
                        onplay: function () {
                            // console.log('onplay');
                        },
                        onpause: function () {
                            setShowPlayBtn(true)
                            // console.log('onpause');
                        },
                        onerror: function (e: any) {
                            // console.log('onerror');
                            // console.log(e);
                        },
                        ontimeupdate: function (time: string) {
                            //   console.log('ontimeupdate-->' + time);
                            // console.log('time',time)
                            // setViewTime(time) 
                            // setVideoId(time)
                            videoTime = parseInt(time)
                        },
                        onseeked: function (time: string) {
                            //  console.log('onseeked-->' + time);
                        }
                    });
                    // player.play('155708372');
                // }
            })
        }
    }, [])
    // function setRecord(){
    //     if(videoObj){

            
    //     }
    // }

    useEffect(() => {
        
    }, [token])


    return <Box className="fixedContainer" sx={{ background: '#f5f5f5' }}>

        <Box onClick={()=>{
            navigate('/learn-detail/'+course_id)
        }} sx={{ background: 'white', padding: '20px 10px', margin: '10px 0', textAlign: 'left' }}>
            <Typography sx={{ color: 'neutral.main' }}> {videoBreadcrumbs} </Typography>
        </Box>
        <Box id="mainContainer" className="w-full" sx={{ height: "300px", position: 'relative' }}>
            {showPlayBtn ? <IconButton
                                            size="large"
                                            edge="start"
                                            color="primary"
                                            aria-label="menu"
                                            sx={{ position: 'absolute',
                                                zIndex: '222',
                                                margin: '0 auto',
                                                display: 'block',
                                                width: '100%',
                                                height: '100%',top:0}}
                                            onClick={() => {
                                                    if (!isPlayed) {
                            
                                                // @ts-ignore
                                                window.player.play(videoId);
                                                setIsPlayed(true)
                                            } else {
                            
                                                // @ts-ignore
                                                window.player.resume()
                                            }
                                            setShowPlayBtn(false)
                                            }}
                                        >
                                            <PlayCircleIcon sx={{fontSize:'132px'}} />
                                        </IconButton> : null}

        </Box>
        {/* <Button onClick={()=>{
            // @ts-ignore
            window.player.play('155708372');
        }}>播放</Button> */}
    </Box>

}
{/* <IconButton
                                            size="large"
                                            edge="start"
                                            color="primary"
                                            aria-label="menu"
                                            sx={{ mr: 2, padding: '5px' }}
                                            onClick={() => {
                                                let name = courseDetail.course_name+'>'+ v.name+'>'+((index + 1)+'.'+(sindex+1))+ s.name
                                                dispatch(setVideoBreadcrumbs({name,course_id:courseDetail.course_id}))
                                                navigate('/learn-video/'+s.small_id)
                                            }}
                                        >
                                            <PlayCircleIcon />
                                        </IconButton> */}

                                        // <img onClick={() => {
                                        //     if (!isPlayed) {
                            
                                        //         // @ts-ignore
                                        //         window.player.play(videoId);
                                        //         setIsPlayed(true)
                                        //     } else {
                            
                                        //         // @ts-ignore
                                        //         window.player.resume()
                                        //     }
                                        //     setShowPlayBtn(false)
                                        // }} style={{
                                        //     position: 'absolute',
                                        //     top: '0',
                                        //     left: '0',
                                        //     zIndex: '999',
                                        //     width: '100%',
                                        //     height: '100%',
                                        // }} src={tempImage} alt="" />