import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack";
import { SetStateAction, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../api/services/LoginService";
import { useAppDispatch } from "../../app/hooks";
import { ResetLearnSlice } from "../../app/LearnSlice";
import { pwdLogin, ResetUserInfoSlice, smsLogin } from "../../app/userInfoSlice";

import logo from '../../static/images/logo-new@2x.png'
import { warningMessage, successMessage } from "../../utils/Message";
import { SendVerifyCode } from "./SendVerifyCode";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function Login() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])
    let [tabsValue, setTabsValue] = useState(0)
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")
    let [phone, setPhone] = useState("")
    let [verifyCode, setVerifyCode] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(()=>{
        // TODO还需要清除redux状态
        localStorage.clear()
        dispatch(ResetLearnSlice())
        dispatch(ResetUserInfoSlice())
    },[])


    return <Box className="fixedContainer">
        <Box className="flex justify-center  mt-40">
            <img src={logo} alt="" />

        </Box>
        <Box sx={{ margin: '0 50px', marginTop: '20px' }}>


            <Tabs
                value={tabsValue}
                onChange={(event, newValue: number) => {
                    setTabsValue(newValue)
                }}
                textColor="inherit"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                variant="fullWidth"
            >
                <Tab label="账号密码登录" {...a11yProps(0)} />
                <Tab label="验证码快速登录" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <Box sx={{ margin: '0 50px' }}>
            <TabPanel value={tabsValue} index={0} >
                <Box sx={{ marginTop: '20px' }}>
                    <TextField value={username} onChange={(event)=>{
                        
                        setUsername(event.target.value)
                    }} fullWidth size="small" label="请输入账号" variant="outlined" />
                    <TextField value={password} onChange={(event)=>{
                        
                        setPassword(event.target.value)
                    }} sx={{ marginTop: '20px' }} size="small" fullWidth
                        type="password" label="请输入密码" variant="outlined" />

                    <Typography onClick={()=>{
                        navigate('/forgetpwd')
                    }} sx={{ opacity: '0.6', marginTop: '20px' }} textAlign="right" variant="subtitle1">忘记密码</Typography>
                    <Button onClick={()=>{
                        if(username=="" || password==""){
                            warningMessage('请输入账号和密码',enqueueSnackbar)
                            
                            // alert('请输入正确手机号码')
                            return
                        }
                        // LoginService.pwdLogin({body:{password:password,phone:username}}).then(res=>{
                        //     // console.log('login',res)
                        //     if(res){
                        //         dispatch(pwdLogin({}))
                        //     }
                        // })
                        dispatch(pwdLogin({password,phone:username})).then(res=>{
                            if(res){
                                navigate('/')
                            }
                        })
                        
                        // if(password==""){
                        //     warningMessage('请输入密码',enqueueSnackbar)
                        //     return 
                        // }
                    }} sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth>登录</Button>
                </Box>
            </TabPanel>
            <TabPanel value={tabsValue} index={1} >
                <Box sx={{ marginTop: '20px' }}>
                    <TextField fullWidth size="small" label="请输入手机号" variant="outlined" value={phone} onChange={(event)=>{
                        
                        setPhone(event.target.value)
                    }}/>
                    <Box className="flex justify-between items-center" sx={{ marginTop: '20px', }}>
                        <TextField  value={verifyCode} size="small"
                            label="请输入验证码" variant="outlined" onChange={(event)=>{
                        
                                setVerifyCode(event.target.value)
                            }}/>
                        

                            {/* <Button sx={{ marginTop: '20px' }} size="large" variant="text" >获取验证码</Button> */}
                            <SendVerifyCode phone={phone} onSendSms={()=>{
                                
                                
                            }}/>
                    </Box>
                    <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                    onClick={()=>{
                        if(phone=="" || phone.length!=11){
                            warningMessage('请输入正确手机号码',enqueueSnackbar)
                            
                            // alert('请输入正确手机号码')
                            return
                        }
                        if(verifyCode==""){
                            warningMessage('请输入验证码',enqueueSnackbar)
                            return 
                        }
                        dispatch(smsLogin({sms_code:verifyCode,phone:phone})).then(res=>{
                            if(res){
                                navigate('/')
                            }
                        })
                    }}
                    >登录</Button>
                </Box>
            </TabPanel>
        </Box>
    </Box>
}