import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Fragment, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

let menuList = [
    { label: '基本信息', id: 1, url: '/basicInfo' },
    { label: '学籍信息', id: 2, url: '/studentInfo' },
    { label: '修改手机号', id: 3, url: '/alterphone' },
    { label: '修改密码', id: 4, url: '/alterpwd' },
]
export function My() {
    let navigate = useNavigate();
    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });
    useEffect(() => { }, [])
    return <Box className="fixedContainer" sx={{ background: '#f5f5f5' }}>
        {userinfo ? <Box className="flex items-center bg-white" sx={{ height: '150px', marginTop: '20px' }}>
            <Box sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '100px',
                borderRadius: '430px',
                overflow: 'hidden',
                height: '100px',
                padding: '0px',
                margin: '25px',
            }}>
                <img src={userinfo.avatar} alt="" />
            </Box>
            <Box className="flex grow text-left flex-col justify-between" sx={{ height: '100%', padding: '30px 0' }}>
                <Typography variant="h5">{userinfo.username}</Typography>
                <Typography sx={{ color: 'neutral.main' }}>{userinfo.phone}</Typography>
            </Box>
        </Box> : null}
        <Box sx={{
            background: 'white', overflow: 'auto',
            position: 'absolute',
            width: '100%',
            marginTop: '20px',
            color: 'neutral.main'
        }}>
            <List>
                {
                    menuList.map(v => {

                        return <><ListItem sx={{ padding: '5px' }} disablePadding key={v.id}>
                            <ListItemButton><ListItemText onClick={() => {
                                navigate(v.url)
                            }} primary={v.label} sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }} />
                                <ListItemIcon sx={{ minWidth: 'auto' }}>
                                    <ChevronRightIcon />
                                </ListItemIcon></ListItemButton>
                        </ListItem>
                            <Divider sx={{ margin: '0 10px' }} /></>
                    })
                }


            </List>
            <Button onClick={() => {
                navigate('/login')
            }} sx={{ margin: '20px 0' }} size="large" variant="text" fullWidth>退出登录</Button>
        </Box>
    </Box>
}