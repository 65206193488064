import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { LoginService } from '../../api/services/LoginService';
import { setAvatar, setOssData } from '../../app/userInfoSlice';


const Input = styled('input')({
  display: 'none',
});
// interface propsType {
//   fileType: uploadType
// }
export default function UploadButtons() {
  // let { fileType } = props
  // const {
  //   ossData
  // } = useAppSelector((state) => {
  //   return state.userInfo;
  // });
  // const { user_id
  // } = useAppSelector((state) => {
  //   return state.userInfo;
  // });
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  function handleImageChange(e: any) {
    LoginService.getOssAuth({ body: { action: 'common' } }).then((ossData) => {
      // if (res?.ret === 0) {

      dispatch(setOssData(ossData))
      // }
      var reader = new FileReader();
      var file = e.target.files[0];

      let fileName = file.name

      const formData = new FormData();
      console.log("one------");

      formData.append('OSSAccessKeyId', ossData.accessid);
      formData.append('Signature', ossData.signature);
      formData.append('host', ossData.host);

      // let key = ossData.key

      let timestamp = new Date().getTime()
      formData.append('timestamp', String(timestamp));


      let today = new Date();

      let key =
        ossData.key +
        today.getFullYear() +
        (today.getMonth() + 1) +
        today.getDate() +
        '/' +
        String(Math.floor(Math.random() * 1000)) +
        '-' +
        '${filename}';

      if (key) {


        key = key.split('-')[0] +
          timestamp +
          '.' +
          fileName.split('.').pop()

        formData.append('key', key);
      }
      formData.append('policy', ossData.policy);
      formData.append('callback', ossData.callback);
      formData.append('file', file);

      enqueueSnackbar('图片上传中...', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 });
      LoginService.uploadFile(ossData?.host, { body: { formData } }).then(res => {
        dispatch(setAvatar(res.filename))
        
      })
    })

  }

  return (
    <Box  >

      <label htmlFor={"icon-button-file"}>
        <Input accept="image/*" id={"icon-button-file"} type="file" onChange={(e) => handleImageChange(e)} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>

    </Box>
  );
}