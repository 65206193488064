import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { saveLocalLoginInfo, setOssData, setPwdSmsCode, setUserInfo } from "../../app/userInfoSlice";
import { warningMessage, errorMessage, successMessage } from "../../utils/Message";
// import { SendVerifyCode } from "./SendVerifyCode";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoginService } from "../../api/services/LoginService";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import UploadButtons from "../Components/uploadButton";

import rawBaseinfo from './rawBasicinfo'
import AreaPick from "../Components/AreaPick/AreaPick";
interface bridgeType {
    [key: string]: any
}
export function BasicInfo() {
    const { enqueueSnackbar } = useSnackbar();



    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOption, setDrawerOption] = useState<Array<string>>([])
    const [drawerPick, setDrawerPick] = useState('')
    const [drawerTextInput, setDrawerTextInput] = useState('')
    const [drawerItemType, setDrawerItemType] = useState('text')
    const [drawerId, setDrawerId] = useState('')
    const [drawerLabel, setDrawerLabel] = useState('')

    function onDrawerClose() {
        setDrawerOpen(false)
        setDrawerPick('')
        setDrawerTextInput('')
        setDrawerItemType('text')
        setDrawerId('')
        setDrawerLabel('')
    }

    const [baseinfoData, setBaseinfoData] = useState(rawBaseinfo)


    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        userinfo,
        ossData,
        avatar,
        avatar_body,
        avatar_pre,
        token
    } = useAppSelector((state) => {
        return state.userInfo;
    });

    useEffect(() => {
        // alert(123)
        rawBaseinfo.forEach(v=>{
            v.value = ''
        })
        if (userinfo) {
            let obj: bridgeType = userinfo
            // rawBaseinfo.forEach(v=>{
            //     nObj[v.id] = v.
            // })
            let nObj = rawBaseinfo.map(v => {

                if (obj[v.id]) {
                    if (v.type == 'select') {
                        if (v.enumData) {

                            v.value = v.enumData[obj[v.id]]
                        }
                    } else {

                        v.value = obj[v.id]
                    }

                }
                return v
            })
            // console.log('setBaseinfoData',obj,nObj)
            // setImgSFZFront(baseInfo.id_card_front_filename)
            // setImgSFZBack(baseInfo.id_card_reverse_filename)
            // setImgOther(baseInfo.other_type_name)
            // console.log('nObj',nObj)
            setBaseinfoData(nObj)
        }
    }, [userinfo])


    return <Box className="fixedContainer">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: "#5b5b5b", boxShadow: 'inherit' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                        navigate('/my')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    基本信息
                </Typography>
            </Toolbar>
        </AppBar>
        {userinfo ? <>
            <List sx={{
                marginTop: '10px',
                paddingBottom: '80px',
                height: 'calc(100% - 170px)',
                overflowY: 'auto',
            }}>

                <ListItem disablePadding >
                    <ListItemButton><ListItemText sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center' }} onClick={() => {
                        // setDrawerId(v.id)
                        // setDrawerOpen(true)
                        // if (v.type === 'select' && v.options) {

                        //     setDrawerOption(v.options)
                        // }
                        // if (v.type === 'text') {

                        //     setDrawerTextInput(v.value)
                        // }
                        // setDrawerLabel(v.label)
                        // setDrawerItemType(v.type)
                    }} primary="头像" secondary={<Box sx={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '100px',
                        overflow: 'hidden',
                        marginLeft: '20px'
                    }}>
                        <img style={{ height: '100%', width: '100%' }} src={avatar_pre+'/'+avatar_body} alt="" />
                    </Box>} />

                        <ListItemIcon sx={{ minWidth: 'auto' }}>

                            <UploadButtons />
                        </ListItemIcon>

                    </ListItemButton>
                </ListItem>
                {baseinfoData.map(v => {
                    return <ListItem sx={{color:v.disabled?'#afafaf':''}} disablePadding key={v.id}>
                        <ListItemButton><ListItemText onClick={() => {
                            if(v.disabled){
                                return 
                            }
                            setDrawerId(v.id)
                            setDrawerOpen(true)
                            if (v.type === 'select' && v.options) {

                                setDrawerOption(v.options)
                            }
                            if (v.type === 'text') {

                                setDrawerTextInput(v.value)
                            }
                            setDrawerLabel(v.label)
                            setDrawerItemType(v.type)
                        }} primary={v.label} secondary={v.value} sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }} />
                            <ListItemIcon sx={{ minWidth: 'auto' }}>
                                <ChevronRightIcon />
                            </ListItemIcon></ListItemButton>
                    </ListItem>
                })}

            </List>
            <Box sx={{ margin: '0 50px', }}>

                <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                    onClick={() => {
                        let o: bridgeType = {}
                        baseinfoData.forEach(v => {
                            if(v.type=='select'){
                                // @ts-ignore
                                o[v.id] = v.enumData[v.value]
                            }else{

                                o[v.id] = v.value
                            }
                        })
                        o['id'] = userinfo.user_id
                        o['avatar'] = avatar_body
                        o['phone'] = userinfo.phone
                        console.log('baseinfoData', o)
                        // @ts-ignore
                        LoginService.user_set({ body: o }).then(res=>{
                            successMessage('修改成功',enqueueSnackbar)
                            LoginService.get_ck_user_info({body:{
                                user_id:userinfo.user_id
                            }}).then(uInfo=>{
                                console.log('uInfo',uInfo)
                                uInfo.token = token
                                dispatch(setUserInfo(uInfo))
                                saveLocalLoginInfo(uInfo)
                            })
                        })
                    }}
                >保存</Button>
            </Box></> : null}
        <Drawer
            anchor={"bottom"}
            open={drawerOpen}
            onClose={() => {

                onDrawerClose()
            }}
        >
            {drawerItemType === 'area' ? 
                <AreaPick onChange={(level1: string,level2: string,level3: string)=>{
                    console.log('level1,level2,level3',level1,level2,level3)
                    let area = ''
                    if(level3){
                        area = level1+'-'+level2+'-'+level3
                    }else if(level2){
                        area = level1+'-'+level2
                    }else{
                        area = level1
                    }
                    setDrawerTextInput(area)
                }}/> : null}


            {drawerItemType === 'select' ? <List>
                {drawerOption.map((text) => (
                    <ListItem button key={text} selected={text === drawerPick} onClick={() => {
                        setDrawerPick(text)

                    }}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> : null}

            {drawerItemType === 'text' ? <Box sx={{ padding: '20px' }} > <TextField fullWidth label={drawerLabel} value={drawerTextInput}
                onChange={(event) => {
                    setDrawerTextInput(event.target.value)
                }} /></Box> : null}


            <Button variant="outlined" sx={{ margin: '20px' }} onClick={() => {


                // let obj = { [drawerId]: drawerItemType === 'select' ? drawerPick : drawerTextInput }



                // @ts-ignore
                // baseinfoData[drawerId].value = drawerItemType === 'select' ? drawerPick : drawerTextInput

                let b = baseinfoData.map(v => {
                    if (v.id == drawerId) {
                        // @ts-ignore
                        v.value = drawerItemType === 'select' ? drawerPick : drawerTextInput
                    }

                    return v
                })

                // console.log('baseinfoData',baseinfoData)

                setBaseinfoData(b)
                onDrawerClose()

                // MyService.Update({ body: { ...obj, user_id: user_id } }).then(res => {
                //     if (res?.ret === 0) {
                //         if (res?.data.length == 0) {
                //             enqueueSnackbar('操作成功', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 });
                //             // setDrawerOpen(false)
                //             onDrawerClose()

                //             MyService.getUserBaseInfo({ body: { user_id: user_id } }).then(res => {
                //                 if (res?.ret === 0) {
                //                     dispatch(setBaseInfo(res.data))
                //                 }
                //             })
                //         }
                //     }
                // })
                // console.log('onClick',drawerItemType,drawerLabel,drawerId)
            }}>确定</Button>
        </Drawer>
    </Box>
}