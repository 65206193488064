import { axios, basePath, getConfigs, IRequestConfig, IRequestOptions } from "../config";

import { SuccessCommom } from "../CommonInterface";

export interface courseList {
  course_name: string
  small_course_count: string
  course_id: string
  course_content: string
  img_cover: string
  course_study_rate: string
}
export interface userCoureListRes {

  class_lesson_count: string
  all_study_rate: string
  study_length: string
  course_list: Array<courseList>
}

export interface courseDetailList {
  type: string
  small_type: number
  live_status: string
  file_url: string
  live_at: string
  name: string
  small_id: string
  count: string
  study_rate: string,
  list: Array<courseDetailList>
}
export interface courseDetailParams {
  user_id: string
  course_id: number
}
export interface courseDetailRes {
  course_name: string
  img_cover: string
  course_id: string
  small_course_count: string
  course_study_rate: string
  course_desc: string
  teacher_desc: string
  list: Array<courseDetailList>
}

export interface cateSmallDetailParams {
  small_id: number
}

export interface cateSmallDetailRes {
  token: string
  domain_url: string
  teacher_name: string
  avatar: string
  video_id: string
  id: string
  small_name: string
  live_room_id: string
  chapter_id: string
  course_type: string
  section_id: string
  small_type: string
  live_status: string
  file_type: string
  student_code: string
  course_id: string
  attachment_count: string
  live_small_start: string
  live_small_time: string
  live_small_status: string
}
export interface videoRecordParams {
  small_id: number
  name: string
  video_time: number
  is_complete: string
  course_id: string
  chapter_id: string
  section_id: string
  video_id: string
}

export class LearnService {
  /**
   * 获取视频点播信息
   */
  static add_ck_video_record(
    params: {
      /** requestBody */
      body?: videoRecordParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/add_ck_video_record';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取视频点播信息
   */
  static get_cate_small_detail(
    params: {
      /** requestBody */
      body?: cateSmallDetailParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<cateSmallDetailRes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/get_cate_small_detail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get_user_course_list(
    params: {
      /** requestBody */
      body?: { user_id: string };
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<userCoureListRes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/get_user_course_list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  static get_ck_course_details(
    params: {
      /** requestBody */
      body?: courseDetailParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<courseDetailRes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/get_ck_course_details';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

}