import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setOssData, setPwdSmsCode } from "../../app/userInfoSlice";
import { warningMessage, errorMessage, successMessage } from "../../utils/Message";
// import { SendVerifyCode } from "./SendVerifyCode";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoginService } from "../../api/services/LoginService";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import UploadButtons from "../Components/uploadButton";

import rawStudentInfo, { sexEnum } from './rawStudentInfo'
import AreaPick from "../Components/AreaPick/AreaPick";
interface bridgeType {
    [key: string]: any
}
export function StudentInfo() {
    const { enqueueSnackbar } = useSnackbar();



    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOption, setDrawerOption] = useState<Array<string>>([])
    const [drawerPick, setDrawerPick] = useState('')
    const [drawerTextInput, setDrawerTextInput] = useState('')
    const [drawerItemType, setDrawerItemType] = useState('text')
    const [drawerId, setDrawerId] = useState('')
    const [drawerLabel, setDrawerLabel] = useState('')

    function onDrawerClose() {
        setDrawerOpen(false)
        setDrawerPick('')
        setDrawerTextInput('')
        setDrawerItemType('text')
        setDrawerId('')
        setDrawerLabel('')
    }

    const [baseinfoData, setBaseinfoData] = useState(rawStudentInfo)


    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        userinfo,
        ossData,
        avatar,
        avatar_body,
        avatar_pre,
    } = useAppSelector((state) => {
        return state.userInfo;
    });

    useEffect(() => {

        if (userinfo) {
            let obj: bridgeType = userinfo
            // rawStudentInfo.forEach(v=>{
            //     nObj[v.id] = v.
            // })
            let nObj = rawStudentInfo.map(v => {

                if (obj[v.id]) {
                    // if (v.type == 'select') {
                    //     if (v.enumData) {

                    //         v.value = v.enumData[obj[v.id]]
                    //     }
                    // } else {
                        if(v.id == 'sex'){
                            v.value  = sexEnum[obj[v.id]]
                        }else{

                            v.value = obj[v.id]
                        }
                    // }

                }
                return v
            })
            // console.log('setBaseinfoData',obj,nObj)
            // setImgSFZFront(baseInfo.id_card_front_filename)
            // setImgSFZBack(baseInfo.id_card_reverse_filename)
            // setImgOther(baseInfo.other_type_name)
            // console.log('nObj',nObj)
            setBaseinfoData(nObj)
        }
    }, [userinfo])


    return <Box className="fixedContainer">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: "#5b5b5b", boxShadow: 'inherit' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                        navigate('/my')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    学籍信息
                </Typography>
            </Toolbar>
        </AppBar>
        {userinfo ? <>
            <List sx={{
                marginTop: '10px',
                height: 'calc(100% - 80px)',
                overflowY: 'auto',
            }}>

                {/* <ListItem disablePadding >
                    <ListItemButton><ListItemText sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center' }} onClick={() => {
                        
                    }} primary="头像" secondary={<Box sx={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '100px',
                        overflow: 'hidden',
                        marginLeft: '20px'
                    }}>
                        <img style={{ height: '100%', width: '100%' }} src={avatar_pre+'/'+avatar_body} alt="" />
                    </Box>} />

                        <ListItemIcon sx={{ minWidth: 'auto' }}>

                            <UploadButtons />
                        </ListItemIcon>

                    </ListItemButton>
                </ListItem> */}
                {baseinfoData.map(v => {
                    return <ListItem sx={{ color: '#afafaf' }} disablePadding key={v.id}>
                        <ListItemButton><ListItemText onClick={() => {
                            // if(v.disabled){
                            //     return 
                            // }
                            // setDrawerId(v.id)
                            // setDrawerOpen(true)
                            // if (v.type === 'select' && v.options) {

                            //     setDrawerOption(v.options)
                            // }
                            // if (v.type === 'text') {

                            //     setDrawerTextInput(v.value)
                            // }
                            // setDrawerLabel(v.label)
                            // setDrawerItemType(v.type)
                        }} primary={v.label} secondary={v.value} sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }} />
                            </ListItemButton>
                    </ListItem>
                })}

            </List></> : null
        }


    </Box>
}