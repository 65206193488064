import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "./hooks";
import { getLocalLoginInfo, setUserInfo } from './userInfoSlice';
// import {  ResponseUserInfoDto } from './userInfoSlice';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  // let auth = useAuth();
  // let location = useLocation();
  const dispatch = useAppDispatch();
  const {
    token
  } = useAppSelector((state) => {
    return state.userInfo;
  });
  
  if (!token) {
    
    let res = getLocalLoginInfo()
    if(res){
      console.log('dispatch')
      dispatch(setUserInfo(res))
    }else{
      return <Navigate to="/login" replace />

    }
    
    
    // return <Navigate to="/" replace />
    

  }

  return children;
}