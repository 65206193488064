import { Box } from "@mui/material"
import { Fragment, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import FixedBottomNavigation from "./Components/FixedBottomNavigation"
import { Learn } from "./Learn"
import { LearnDetail } from "./Learn/detail"
import { LearnVideo } from "./Learn/LearnVideo"
import { My } from "./My"

export function Home() {
    useEffect(() => {},[])
    return <Box>
        <Fragment>
        <Routes>
          <Route path="" element={<Learn />}>
            
          </Route>
          <Route path="/learn"  element={<Learn />}></Route>
          <Route path="/learn-detail/:id" element={<LearnDetail />}></Route>
          <Route path="/learn-video/:id" element={<LearnVideo />}></Route>

          <Route path="/my"  element={<My />}>
            
          </Route>
          {/* <Route  path="/item/:collectionId/:todolistId" element={<TodolistItemWrap />}>
            
          </Route>
          <Route  path="/" element={<TodolistCollection />}>
            
          </Route> */}
        </Routes>
        </Fragment>
        <FixedBottomNavigation />
    </Box>
}