import { Box, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {  setPwdSmsCode } from "../../app/userInfoSlice";
import { warningMessage, errorMessage, successMessage } from "../../utils/Message";
// import { SendVerifyCode } from "./SendVerifyCode";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoginService } from "../../api/services/LoginService";
export function AlterPwd() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])

    let [password, setPassword] = useState("")
    let [rawPwd, setRawPwd] = useState("")
    let [confirmPassword, setConfirmPassword] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        userinfo
      } = useAppSelector((state) => {
        return state.userInfo;
      });
    

    return <Box className="fixedContainer">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: "#5b5b5b", boxShadow: 'inherit' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                        navigate('/my')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    修改密码
                </Typography>
            </Toolbar>
        </AppBar>
        <Box sx={{ margin: '0 50px', marginTop: '200px', }}>
            <TextField fullWidth size="small" label="原始密码" variant="outlined" type="password" value={rawPwd} onChange={(event) => {

                setRawPwd(event.target.value)
            }} />
            <TextField sx={{ marginTop: '20px' }} fullWidth size="small" label="新密码" variant="outlined" type="password" value={password} onChange={(event) => {

                setPassword(event.target.value)
            }} />
            <TextField type="password" sx={{ marginTop: '20px' }} fullWidth value={confirmPassword} size="small"
                label="确认密码" variant="outlined" onChange={(event) => {

                    setConfirmPassword(event.target.value)
                }} />
            <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                onClick={() => {
                    // if (phone == "" || phone.length != 11) {
                    //     warningMessage('请输入正确手机号码', enqueueSnackbar)

                    //     // alert('请输入正确手机号码')
                    //     return
                    // }
                    // if (verifyCode == "") {
                    //     warningMessage('请输入验证码', enqueueSnackbar)
                    //     return
                    // }
                    // dispatch(smsLogin({sms_code:verifyCode,phone:phone})).then(res=>{
                    //     if(res){
                    //         navigate('/')
                    //     }
                    // })
                    // dispatch(setPwdSmsCode(verifyCode))

                    

                    if(rawPwd=='' || password=='' || confirmPassword==''){

                        warningMessage('请输入密码', enqueueSnackbar)
                        return
                    }

                    if(password!=confirmPassword){
                        warningMessage('新密码不一致', enqueueSnackbar)
                        return
                    }
                    // dispatch(setPwd())
                    // navigate('/login')
                    let phone = userinfo?.phone || ''
                    LoginService.user_update_pwd({ body: { password:rawPwd,new_password:password,phone} }).then(res=>{
                        // console.log('resetpwd',res)
                        successMessage('密码已重置，请重新登录', enqueueSnackbar,3000)
                        navigate('/login')
                    })
                }}
            >完成</Button>
        </Box>
    </Box>
}