import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack";
import { SetStateAction, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../api/services/LoginService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { warningMessage } from "../../utils/Message";
import { SendVerifyCode } from "../Login/SendVerifyCode";


// import logo from '../../static/images/logo-new@2x.png'
// import { warningMessage, successMessage } from "../../utils/Message";
// import { SendVerifyCode } from "./SendVerifyCode";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export function AlterPhone() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])
    let [tabsValue, setTabsValue] = useState(0)
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")
    // let [phone, setPhone] = useState("")
    let [verifyCode, setVerifyCode] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });

    useEffect(() => {
        if(!userinfo){
            navigate('/')
        }
        
    }, [])


    return <Box className="fixedContainer">
        <AppBar position="static" sx={{backgroundColor:'white',color:"#5b5b5b",boxShadow:'inherit'}}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={()=>{
                        navigate('/my')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    修改手机号码
                </Typography>
            </Toolbar>
        </AppBar>
        {userinfo?
        <Box sx={{ margin: '20px',marginTop:'50px' }}>
            <TextField disabled fullWidth size="small" label="请输入手机号" variant="outlined" value={userinfo.phone}  />
            <Box className="flex justify-between items-center" sx={{ marginTop: '20px', }}>
                <TextField  value={verifyCode} size="small"
                    label="请输入验证码" variant="outlined" onChange={(event) => {

                        setVerifyCode(event.target.value)
                    }} />


                {/* <Button sx={{ marginTop: '20px' }} size="large" variant="text" >获取验证码</Button> */}
                <SendVerifyCode phone={userinfo.phone} onSendSms={() => {


                }} />
            </Box>
            <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                onClick={() => {
                    // if (phone == "" || phone.length != 11) {
                    //     warningMessage('请输入正确手机号码', enqueueSnackbar)

                    //     // alert('请输入正确手机号码')
                    //     return
                    // }
                    if (verifyCode == "") {
                        warningMessage('请输入验证码', enqueueSnackbar)
                        return
                    }
                    LoginService.user_check_code({body:{phone:userinfo.phone,sms_code:verifyCode}}).then(res=>{
                        navigate('/alterphone-newphone')
                    })
                    
                    // dispatch(smsLogin({ sms_code: verifyCode, phone: phone })).then(res => {
                    //     if (res) {
                    //         navigate('/')
                    //     }
                    // })
                }}
            >下一步</Button>
        </Box>:null
        }
    </Box>

} 