import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
// import {
//   LoginDto,
//   AuthService,
//   serviceOptions,
//   ResponseUserInfoDto,
// } from "../api/swaggerApi/index";

import { AxiosError } from 'axios'
import { serviceOptions } from "../api/config";
import { LoginService, smsLoginParams, pwdLoginParams, User, setPwdParams, OssAuthObj } from "../api/services/LoginService";
// import { RecordUserInfo, RelateInfo, UserService, WechatLoginResponse } from "../api/services/UserService";
// import { hiddenPhone } from "../utils/hiddenPhone";


type userInfoItem = {
  id: string,
  name: string,
}

export interface LoginDto {
  /**  */
  username: string;

  /**  */
  password: string;
}

interface ValidationErrors {
  errorMessage: string
  field_errors: Record<string, string>
}


export function saveLocalLoginInfo(item:User){
  localStorage.setItem('loginInfo',JSON.stringify(item))
}
export function getLocalLoginInfo():User|null{
  let r = localStorage.getItem('loginInfo')
  if(r){
    let format = JSON.parse(r)
    return format as User
  }
  return null
}


// export const setPwd = createAsyncThunk<
// null,
// setPwdParams,
//   // { id: string } & Partial<LoginDto>,
//   {
//     rejectValue: ValidationErrors
//   }
// >('userInfoSlice/setPwd', async (params, { rejectWithValue }) => {
//   try {
//     let result = await LoginService.setPwd({ body: { password:params.password,phone:params.phone,sms_code:params.sms_code} })
    
    
//     return result

    
//   } catch (err: any) {
//     let error: AxiosError<ValidationErrors> = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     return rejectWithValue(error.response.data)
//   }
// })
export const pwdLogin = createAsyncThunk<
User,
pwdLoginParams,
  // { id: string } & Partial<LoginDto>,
  {
    rejectValue: ValidationErrors
  }
>('userInfoSlice/pwdLogin', async (params, { rejectWithValue }) => {
  try {
    let result = await LoginService.pwdLogin({ body: { password:params.password,phone:params.phone} })
    // result.data
    // if(res){
    //   return res?.data.token
    // }
    console.log('pwdLogin',result)
    serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
        "Bearer " + result.token;
        saveLocalLoginInfo(result)
    return result

    
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})


export const smsLogin = createAsyncThunk<
User,
smsLoginParams,
  // { id: string } & Partial<LoginDto>,
  {
    rejectValue: ValidationErrors
  }
>('userInfoSlice/smsLogin', async (params, { rejectWithValue }) => {
  try {
    let result = await LoginService.smsLogin({ body: { sms_code:params.sms_code,phone:params.phone} })
    console.log('result',result)
    // result.data
    // if(res){
    //   return res?.data.token
    // }
    serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
        "Bearer " + result.token;
        saveLocalLoginInfo(result)
    return result
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})

// 创建一个实体适配器，可以对实体进行 CURD 操作
// https://redux-toolkit.js.org/api/createEntityAdapter
// const someEntityAdapter = createEntityAdapter<userInfoItem>({
//   // Assume IDs are stored in a field other than `book.id`
//   // 可以手动指定实体的 id 字段
//   //   selectId: (book) => book.bookId,

//   // Keep the "all IDs" array sorted based on book titles
//   // 设置实体的排序依据（有 id 的数据）
//   //   sortComparer: (a, b) => a.title.localeCompare(b.title),
// });


let initialState = {
  ossData:<OssAuthObj|null> null,
  
  pwdSmsCode:'',
  pwdSmsPhone:'',
  pwdLoginLoading:false,
  smsLoginLoading:false,

  alterPhoneCode:'',
  alertPhoneLoading:false,

  avatar:'',
  avatar_body:'',
  avatar_pre:'',

  token:'',
  videoBreadcrumbs:'',
  
  userinfo:<User|null>null
}
// let initArray: Array<RelateInfo> = []
export const userInfoSlice = createSlice({
  name: "userInfoSlice",
  initialState: initialState,
  reducers: {
    ResetUserInfoSlice(state) {
      // ✅ CORRECT: returns a new value to replace the old one
      return initialState
    },
    setAvatar(state, action: PayloadAction<string>){
      state.avatar_body = action.payload
      // state.avatar =state.ossData?.host + '/' + action.payload
      // state.avatarPath =action.payload
    },
    setOssData(state, action: PayloadAction<OssAuthObj>) {
      state.ossData = action.payload
    },
    setVideoBreadcrumbs(state,action: PayloadAction<string>){
      state.videoBreadcrumbs = action.payload
    },
    setAlterPhoneCode(state,action: PayloadAction<{code:string}>){
      state.alterPhoneCode = action.payload.code
    },
    setPwdSmsCode(state,action: PayloadAction<{code:string,phone:string}>){
      state.pwdSmsCode = action.payload.code
      state.pwdSmsPhone = action.payload.phone
    },
    setUserInfo(state,action: PayloadAction<User>){
      state.token = action.payload.token
      state.userinfo = action.payload
      state.avatar = action.payload.avatar
      state.avatar_body = action.payload.avatar_body
      state.avatar_pre = action.payload.avatar_pre
      serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
        "Bearer " +action.payload.token;
    },
    loginOut(state){
      state.token = ''
      state.userinfo = null
      localStorage.clear()
    },
    // setUserInfo(state, action: PayloadAction<User>) {
    //   // state.user_id = action.payload.user_id

    //   // state.recordUserInfo = action.payload
    //   // let obj = action.payload
    //   // let obj = JSON.parse(JSON.stringify(action.payload)) 
    //   // obj.phone = hiddenPhone(obj.phone)
    //   state.userinfo = action.payload
    // },
   
    
  },
  extraReducers: (builder) => {
    builder.addCase(smsLogin.pending, (state, { payload }) => {
      state.smsLoginLoading = true
      // state.token = payload.data.token
      // state.userinfo = payload.data.User
    })
    builder.addCase(smsLogin.fulfilled, (state, { payload }) => {
      state.token = payload.token
      state.userinfo = payload
      
      state.avatar = payload.avatar
      state.avatar_pre = payload.avatar_pre
      state.avatar_body = payload.avatar_body

      state.smsLoginLoading = false
    })
    builder.addCase(smsLogin.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        // state.error = action.payload.errorMessage
      } else {
        // state.error = action.error.message!
      }
    })

    builder.addCase(pwdLogin.pending, (state, { payload }) => {
      state.pwdLoginLoading = true
      // state.token = payload.data.token
      // state.userinfo = payload.data.User
    })
    builder.addCase(pwdLogin.fulfilled, (state, { payload }) => {
      state.token = payload.token
      state.userinfo = payload
      state.avatar = payload.avatar
      state.avatar_pre = payload.avatar_pre
      state.avatar_body = payload.avatar_body
      state.pwdLoginLoading = false
    })
    builder.addCase(pwdLogin.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        // state.error = action.payload.errorMessage
      } else {
        // state.error = action.error.message!
      }
    })
  },
});

export const {
  ResetUserInfoSlice,
  setAvatar,
  setOssData,
  loginOut,
  setUserInfo,
  setPwdSmsCode
  // setUserInfo
  // setRecordUserInfo,
  // loadUserInfo,
  // setOpenId,
  // setCacheRelateRecord,
  // selectRecord
} = userInfoSlice.actions;
// export {...userInfoSlice.actions}

// export const userInfoSlice.actions;

export default userInfoSlice.reducer;

// export const userInfoItemSelectors = someEntityAdapter.getSelectors();
