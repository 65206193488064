import { Box, List, Typography } from "@mui/material"
import { Fragment, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { courseList } from "../../api/services/LearnService"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { get_user_course_list } from "../../app/LearnSlice"
import tempImage from '../../static/images/noImg.png'

var moment = require('moment');

let largeFont = {
    fontSize: '34px',
    fontWeight: 'bold',
    marginBottom: '20px'
}

export function Learn() {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (userinfo) {

            dispatch(get_user_course_list({ user_id: userinfo?.user_id }))
        }
    }, [])


    const {
        courseInfo
    } = useAppSelector((state) => {
        return state.LearnSlice;
    });
    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });


    function renderListItem(course_list:Array<courseList>) {
        
            if (course_list.length > 0) {



                return <List sx={{
                    marginTop: '10px',
                    paddingBottom: '80px',
                    height: 'calc(100% - 170px)',
                    overflowY: 'auto',
                }}>
                    {
                        course_list?.map(v => {

                                return <Box key={v.course_name} onClick={() => {
                                    navigate('/learn-detail/'+v.course_id)
                                }} className="flex items-center bg-white" sx={{ height: '150px', marginBottom: '10px' }}>
                                    <Box sx={{
                                        width: '200px',
                                        height: '150px',
                                        padding: '20px',alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}>
                                        <img src={v.img_cover} alt="" />
                                    </Box>
                                    <Box className="flex grow text-left flex-col justify-between" sx={{ height: '100%', padding: '30px 0' }}>
                                        <Typography variant="h5">{v.course_name}</Typography>
                                        <Typography sx={{ color: 'neutral.main' }}>{v.small_course_count}课时</Typography>
                                        <Typography sx={{ color: 'neutral.main' }}>已学习{v.course_study_rate}</Typography>
                                    </Box>
                                </Box>
                        })
                    }

                </List>


            } else {
                return <Typography id="modal-modal-title" variant="subtitle1" component="h2" sx={{ textAlign: 'center', marginTop: '200px', }} className='text-gray-400 '>
                    暂无课程
                </Typography>
            }
    }

    return <Box className="fixedContainer" sx={{ background: '#f5f5f5' }}>
        {courseInfo ? <><Box className="grid grid-cols-3 gap-2 learn-header" sx={{ padding: '30px', marginTop: '20px', backgroundColor: "primary.main", color: 'white' }} >
            <Box sx={largeFont}>{courseInfo?.class_lesson_count}门</Box>
            <Box sx={largeFont}>{courseInfo?.all_study_rate}%</Box>
          
            <Box sx={largeFont}>{moment.duration(courseInfo?.study_length)._data.hours}小时</Box>
            <Box>学习课程</Box>
            <Box>学习进度</Box>
            <Box>学习时长</Box>
        </Box>{renderListItem(courseInfo?.course_list)}</> : null}


    </Box>
}