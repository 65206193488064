import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import LearnSlice from './LearnSlice';
// import CourseServiceSlice from './CourseServiceSlice';
import userInfoSlice from './userInfoSlice';
// import My from './My';
// import CourseServiceSlice from '../pages/CourseService/slice/CourseServiceSlice';
// import SchoolSlice from '../pages/School/slice';
// import wechatSlice from './wechatSlice';
// import ApplySlice from '../pages/Apply/slice.ts/ApplySlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userInfo:userInfoSlice,
    LearnSlice
    // CourseServiceSlice,
    // My,
    // SchoolSlice,
    // wechatSlice,
    // ApplySlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
