import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';

import { useLocation } from "react-router-dom";

export default function FixedBottomNavigation() {
  // let {currentValue} = props
  // 根据路由判断初始值

  let location = useLocation()

  React.useEffect(() => {
    
    console.log('location',location.key)
    if (location.pathname == '/my') {
      setValue(1)
    }
    if (location.pathname == '/learn' || location.pathname == '/') {
      setValue(0)
    }
  })

  const [value, setValue] = React.useState(-1);


  let navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  // const [messages, setMessages] = React.useState(() => refreshMessages());

  React.useEffect(() => {
    // (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
    // setMessages(refreshMessages());
    // 获取url判断
  }, [value]);
  // TODO页面返回时底部不正确

  return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction onClick={() => { navigate('/learn') }} label="学习" icon={<HomeIcon />} />
      <BottomNavigationAction onClick={() => { navigate('/my') }} label="我的" icon={<PersonIcon />} />
      {/* <BottomNavigationAction  onClick={()=>{navigate('/apply')}} label="报名" icon={<ArchiveIcon />} />
    <BottomNavigationAction  onClick={()=>{navigate('/my')}}  label="我的" icon={<ArchiveIcon />} /> */}
    </BottomNavigation>
  </Paper>
}

