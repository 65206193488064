import { Box, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setPwdSmsCode } from "../../app/userInfoSlice";
import { warningMessage } from "../../utils/Message";
import { SendVerifyCode } from "./SendVerifyCode";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
export function ForgetPwd() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [])

    let [phone, setPhone] = useState("")
    let [verifyCode, setVerifyCode] = useState("")

    let navigate = useNavigate();

    const dispatch = useAppDispatch();




    return <Box className="fixedContainer">
        <AppBar position="static" sx={{backgroundColor:'white',color:"#5b5b5b",boxShadow:'inherit'}}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={()=>{
                        navigate('/login')
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    找回密码
                </Typography>
            </Toolbar>
        </AppBar>
        <Box sx={{ margin: '0 50px', marginTop: '200px', }}>
            <TextField fullWidth size="small" label="请输入手机号" variant="outlined" value={phone} onChange={(event) => {

                setPhone(event.target.value)
            }} />
            <Box className="flex justify-between items-center" sx={{ marginTop: '20px', }}>
                <TextField value={verifyCode} size="small"
                    label="请输入验证码" variant="outlined" onChange={(event) => {

                        setVerifyCode(event.target.value)
                    }} />


                {/* <Button sx={{ marginTop: '20px' }} size="large" variant="text" >获取验证码</Button> */}
                <SendVerifyCode phone={phone} onSendSms={() => {


                }} />
            </Box>
            <Button sx={{ marginTop: '20px' }} size="large" variant="contained" fullWidth
                onClick={() => {
                    if (phone == "" || phone.length != 11) {
                        warningMessage('请输入正确手机号码', enqueueSnackbar)

                        // alert('请输入正确手机号码')
                        return
                    }
                    if (verifyCode == "") {
                        warningMessage('请输入验证码', enqueueSnackbar)
                        return
                    }
                    // dispatch(smsLogin({sms_code:verifyCode,phone:phone})).then(res=>{
                    //     if(res){
                    //         navigate('/')
                    //     }
                    // })
                    dispatch(setPwdSmsCode({code:verifyCode,phone:phone}))
                    navigate('/resetPwd')
                }}
            >下一步</Button>
        </Box>
    </Box>
}