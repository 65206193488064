import { Box, IconButton, List, Tab, Tabs, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import tempImage from '../../static/images/noImg.png'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { get_ck_course_details, setVideoBreadcrumbs } from "../../app/LearnSlice";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function LearnDetail() {
    let { id } = useParams();

    const dispatch = useAppDispatch();


    let [tabsValue, setTabsValue] = useState(0)
    let navigate = useNavigate();
    const {
        userinfo
    } = useAppSelector((state) => {
        return state.userInfo;
    });
    const {
        courseDetail
    } = useAppSelector((state) => {
        return state.LearnSlice;
    });
    useEffect(() => {
        if (userinfo && id) {
            dispatch(get_ck_course_details({ user_id: userinfo?.user_id, course_id: Number(id) }))
        }
    }, [])

    return <Box className="fixedContainer" sx={{ background: '#f5f5f5', }}>

        {courseDetail ? <><Box className="flex items-center bg-white" sx={{ height: '150px', marginTop: '20px' }}>
            <Box sx={{
                width: '200px',
                height: '150px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}>
                <img src={courseDetail.img_cover} alt="" />
            </Box>
            <Box className="flex grow text-left flex-col justify-between" sx={{ height: '100%', padding: '30px 0' }}>
                <Typography variant="h5">{courseDetail.course_name}</Typography>
                <Typography sx={{ color: 'neutral.main' }} >{courseDetail.small_course_count}课时</Typography>
                <Typography sx={{ color: 'neutral.main' }} >已学习{courseDetail.course_study_rate}%</Typography>
            </Box>
        </Box>
            <Box sx={{ padding: '0 20px', marginTop: '20px', background: 'white' }}>


                <Tabs
                    value={tabsValue}
                    onChange={(event, newValue: number) => {
                        setTabsValue(newValue)
                    }}
                    textColor="inherit"
                    indicatorColor="primary"
                    aria-label="secondary tabs example"
                    variant="fullWidth"

                >
                    <Tab label="课程列表" sx={{ fontSize: '16px' }} {...a11yProps(0)} />
                    <Tab label="课程介绍" sx={{ fontSize: '16px' }} {...a11yProps(1)} />
                    <Tab label="老师介绍" sx={{ fontSize: '16px' }}  {...a11yProps(2)} />
                </Tabs>
            </Box>
            <Box sx={{
                background: 'white', padding: '20px 0', overflow: 'auto',
                height: 'calc(100% - 300px)',
                position: 'absolute',
                width: '100%',
            }}>
                <TabPanel value={tabsValue} index={0} >
                    {courseDetail.list.map((v, index) => {
                        return <Box sx={{ marginTop: '10px', marginLeft: '20px' }} >
                            <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: '20px' }}>{v.name}</Typography>
                            {v.list.map((s, sindex) => {



                                return <Box sx={{ color: 'neutral.main' }} className="flex items-center justify-between">
                                    {/* {(index + 1) + '.' + (sindex + 1) + ' '} */}
                                    <Typography className="grow text-left ">{s.name} 已学习 {s.study_rate}%</Typography>
                                    {s.small_type == 3 ?
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="primary"
                                            aria-label="menu"
                                            sx={{ mr: 2, padding: '5px' }}
                                            onClick={() => {
                                                let name = courseDetail.course_name + '>' + v.name + '>' + ((index + 1) + '.' + (sindex + 1)) + s.name
                                                dispatch(setVideoBreadcrumbs({ name, course_id: courseDetail.course_id,chapter_id:v.small_id,section_id:s.small_id,section_name:s.name }))
                                                navigate('/learn-video/' + s.small_id)
                                            }}
                                        >
                                            <PlayCircleIcon />
                                        </IconButton>
                                        : null}
                                </Box>

                            })}
                        </Box>
                    })}


                </TabPanel>
                <TabPanel value={tabsValue} index={1} >
                    <Box sx={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: courseDetail.course_desc }}>
                    </Box>
                </TabPanel>
                <TabPanel value={tabsValue} index={2} >
                    <Box sx={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: courseDetail.teacher_desc }}>
                    </Box>
                </TabPanel>
            </Box></> : null}

    </Box>
}